import React from 'react';

import { ButtonPriority, TextArea } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { DATA_HOOKS } from './constants';
import { st, classes } from './DeclineGroupRequestModal.st.css';

import { Button } from 'common/components/Button';
import { Modal } from '../../../../../common/components/Modal/Modal';

export interface DeclineGroupRequestModalProps {
  creatorName: string;

  onConfirmGroupRejection(rejectionReason: any): void;

  onRequestClose(e: any): void;
}

export const DeclineGroupRequestModal: React.FC<
  DeclineGroupRequestModalProps
> = (props) => {
  const { t } = useTranslation();
  const [rejectionReason, setRejectionReason] = React.useState<string>('');

  const handleRejectionReasonChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setRejectionReason(e.target.value);
  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onConfirmGroupRejection(rejectionReason);
  };
  return (
    <form
      data-hook={DATA_HOOKS.FORM}
      onSubmit={handleOnSubmit}
      className={st(classes.root)}
    >
      <Modal.Header
        title={t('groups-web.modal.decline-group.title')}
        subtitle={t('groups-web.modal.decline-group.subtitle', {
          memberName: props.creatorName,
        })}
      />
      <TextArea
        data-hook={DATA_HOOKS.REJECTION_REASON_INPUT}
        placeholder={t(
          'groups-web.modal.decline-group.rejectionReason.placeholder',
        )}
        onChange={handleRejectionReasonChange}
        value={rejectionReason}
        ariaLabel={t(
          'groups-web.modal.decline-group.rejectionReason.placeholder',
        )}
        className={classes.rejectionReasonInput}
      />
      <Modal.Buttons>
        <Button
          bw={true}
          className={classes.cancelButton}
          priority={ButtonPriority.secondary}
          onClick={props.onRequestClose}
        >
          {t('groups-web.modal.decline-group.action.cancel')}
        </Button>
        <Button
          bw={true}
          className={classes.declineButton}
          type="submit"
          priority={ButtonPriority.primary}
        >
          {t('groups-web.modal.decline-group.action.decline')}
        </Button>
      </Modal.Buttons>
    </form>
  );
};

DeclineGroupRequestModal.displayName = 'DeclineGroupRequestModal';
