import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';

import { Dialog, DialogProps } from '../Dialog';
import { Button } from '../Button';

export interface WebOutProps extends DialogProps {
  buttonLabelKey: string;
  inviteLink: string;
  loadMemberInvitesLink(): void;
}

// Can not wrap withMemberInvites here, because different context instances will be used in groups-app/group and common
export class WebOut extends React.Component<WebOutProps> {
  componentDidMount() {
    this.props.loadMemberInvitesLink();
  }

  render() {
    const { inviteLink, buttonLabelKey, loadMemberInvitesLink, ...restProps } =
      this.props;
    return (
      <Dialog
        {...restProps}
        buttons={
          <Button bw={true} priority={ButtonPriority.primary} href={inviteLink}>
            {buttonLabelKey}
          </Button>
        }
      />
    );
  }
}
