import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Button, Text } from 'wix-ui-tpa';

import { WebOut } from '../../../../common/components/WebOut/WebOut';
import { useMemberInvites } from '../../../../common/context/member-invites/useMemberInvites';

import { st, classes } from './PendingGroupList.st.css';
import { PluralText } from 'common/components/PluralText';

export interface PendingGroupsMobileProps {
  total: number;
}

export const PendingGroupsMobile: React.FC<PendingGroupsMobileProps> = (
  props,
) => {
  const { total } = props;
  const { t } = useTranslation();
  const { memberInvitesLink, loadMemberInvitesLink } = useMemberInvites();

  const [isOpened, setIsOpened] = React.useState(false);

  const openWebOut = () => setIsOpened(true);
  const closeWebOut = () => setIsOpened(false);

  return (
    <>
      <Button
        onClick={openWebOut}
        className={st(classes.root, { mobile: true })}
      >
        <Text className={classes.mobileLabel}>
          <PluralText
            translationKey="groups-web.group-list.pending-groups-mobile.label"
            count={total}
          />
        </Text>
        &nbsp;
        <Text className={classes.mobileCta}>
          {t('groups-web.group-list.pending-groups-mobile.cta')}
        </Text>
      </Button>
      <WebOut
        inviteLink={memberInvitesLink}
        loadMemberInvitesLink={loadMemberInvitesLink}
        buttonLabelKey={t('groups-web.modal.out.button')}
        title={t('groups-web.modal.out.pending-groups.title')}
        caption={t('groups-web.modal.out.pending-groups.description')}
        isOpen={isOpened}
        onClose={closeWebOut}
      />
    </>
  );
};

PendingGroupsMobile.displayName = 'PendingGroupsMobile';
