import React from 'react';
import { st, classes } from './SideBarLayout.st.css';
import { GroupRequestsList } from '../../PendingGroupList/GroupRequestsList';
import { LayoutProps } from '../LayoutProps';
import { SideBar } from '../../SideBar/SideBar';
import { CentralFeed } from '../../CentralFeed/CentralFeed';
import { settingsParams } from '../../../../../common/settings/groups-list/settings';
import { DATA_HOOKS } from '../../../../../common/components/GroupList/dataHooks';
import { CreateGroupButton } from 'Groups/Widget/Groups/CreateGroupButton';
import { ButtonPriority } from 'wix-ui-tpa';
import { NoGroups } from '../../../../../common/components/GroupList/NoGroups';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useGroupsList } from '../../../contexts/useGroupsList';
import { isApprovalStatusPending } from '../IsApprovalStatusPending';
import { useSettings } from '@wix/tpa-settings/react';

type SideBarLayoutProps = LayoutProps;

export const SideBarLayout: React.FC<SideBarLayoutProps> = (props) => {
  const { t } = useTranslation();
  const { groups, groupNameQuery } = useGroupsList();
  const settings = useSettings();

  const notPendingGroups = React.useMemo(() => {
    return groups.filter((g) => !isApprovalStatusPending(g));
  }, [groups]);

  if (groups && !groups.length && !groupNameQuery) {
    return (
      <NoGroups
        data-hook={DATA_HOOKS.noGroups}
        emptyStateHeader={t('groups-web.empty-state.header')}
        emptyStateText={t('groups-web.empty-state.text')}
        actionButton={
          <CreateGroupButton
            priority={ButtonPriority.primary}
            onClick={props.onCreateGroupClick}
            data-hook={DATA_HOOKS.noGroupsCreateButton}
            biOrigin="empty_state_btn"
          >
            {t('groups-web.empty-state.cta')}
          </CreateGroupButton>
        }
      />
    );
  }

  return (
    <div
      className={st(classes.root, {
        alignment: settings.get(settingsParams.sideBarAlignment),
      })}
    >
      <div className={classes.content}>
        <GroupRequestsList />
        <CentralFeed />
      </div>
      <SideBar
        groups={notPendingGroups}
        onCreateButtonClick={props.onCreateGroupClick}
      />
    </div>
  );
};
