import React, { CSSProperties } from 'react';
import { Text, TextProps } from 'wix-ui-tpa';
import { st, classes /* ... */ } from './Header.st.css';

interface HeaderProps extends TextProps {
  textAlign?: CSSProperties['textAlign'];
}

/**
 * Header
 *
 * Component for header text
 */
export const Header: React.FC<HeaderProps> = (props) => (
  <Text
    tagName="h2"
    {...props}
    className={st(
      classes.root,
      { align: props.textAlign || '' },
      props.className,
    )}
  />
);

Header.displayName = 'Header';
