import React from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

import { Block } from '../../../../common/components/Block';
import { Search } from '../../../../common/components/Search/Search';

import { SortBy } from './SortBy';
import { DATA_HOOKS } from './dataHooks';
import { AppRootActions } from '../App/types';

import { st, classes } from './SearchSortingBox.st.css';

export interface SearchSortingBoxProps {
  onSearch: AppRootActions['searchGroups'];
  onSort: AppRootActions['sortGroups'];
  initialSortSelectedId: string;
}

export const SearchSortingBox: React.FC<SearchSortingBoxProps> = ({
  onSort,
  initialSortSelectedId,
  onSearch,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, { mobile: isMobile })}
      data-hook={DATA_HOOKS.searchSortingBox}
      key={DATA_HOOKS.searchSortingBox}
    >
      <Block
        end={
          <Search
            withCloseButton={true}
            placeholder={t('groups-web.search.placeholder')}
            onChange={onSearch}
            className={classes.search}
          />
        }
        stylesOverride={{
          gridTemplateColumns: 'minmax(0, max-content)',
        }}
      >
        <div className={classes.overflowX}>
          <SortBy
            onChange={onSort}
            initialSelectedId={initialSortSelectedId}
            className={classes.sortBy}
            mobile={isMobile}
          />
        </div>
      </Block>
    </div>
  );
};

SearchSortingBox.displayName = 'SearchSortingBox';
