import React from 'react';
import { ApiTypesV1GroupResponse } from '@wix/social-groups-api';
import { IconButton } from 'wix-ui-tpa';
import { groupCoverUrl } from 'common/utils/groupCoverUrl';
import { useGroupWrapper } from 'common/hooks/useGroupWrapper';
import { GroupCardMedia } from 'common/components/GroupCard/media/GroupCardMedia';
import { GroupCardMeta } from 'common/components/GroupCard/meta/GroupCardMeta';
import { ChevronRight } from 'wix-ui-icons-common';
import { classes } from './GroupCard.st.css';

type GroupCardProps = {
  group: ApiTypesV1GroupResponse;
  goToGroup?(groupId: string): void;
  withImage: boolean;
};

export const GroupCard: React.FC<GroupCardProps> = ({
  group,
  goToGroup,
  withImage,
}) => {
  const g = useGroupWrapper(group);
  const imageUrl = groupCoverUrl(g, { targetHeight: 40 });

  return (
    <div key={g.id} className={classes.root} onClick={handleGroupClick}>
      {withImage ? (
        <div className={classes.groupImage}>
          <GroupCardMedia url={imageUrl} groupUrl="" aspectRatio="square" />
        </div>
      ) : null}
      <GroupCardMeta
        group={group}
        groupUrl=""
        alwaysShowMemberRequests={true}
        truncate={true}
      />
      <IconButton icon={<ChevronRight />} className={classes.icon} />
    </div>
  );

  function handleGroupClick() {
    goToGroup?.(g.id);
  }
};
