import React from 'react';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { ButtonPriority, Text, TextTypography } from 'wix-ui-tpa';
import { groupsPageView, groupsSearchForGroup } from '@wix/bi-logger-groups/v2';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { stylesParams } from '../../../../../common/settings/groups-list/styles';
import { BIUserEntry } from '../../../../../common/bi-logger/types';
import { settingsParams } from '../../../../../common/settings/groups-list/settings';
import { GroupList } from '../../../../../common/components/GroupList/GroupList';
import { useGroupsActions } from '../../../contexts/useGroupsActions';
import { useGroupsList } from '../../../contexts/useGroupsList';
import { GroupRequestsList } from '../../PendingGroupList/GroupRequestsList';
import { SearchSortingBox } from '../../SearchSortingBox/SearchSortingBox';
import { DATA_HOOKS } from '../dataHooks';
import { LayoutProps } from '../LayoutProps';
import { CreateGroupButton } from '../CreateGroupButton';
import { classes, st } from './ListLayout.st.css';

type ListLayoutProps = LayoutProps;

export const ListLayout: React.FC<ListLayoutProps> = (props) => {
  const settings = useSettings();
  const styles = useStyles();
  const { t } = useTranslation();
  const bi = useBi();
  const actions = useGroupsActions();
  const { isMobile } = useEnvironment();
  const { groupsSortBy } = useGroupsList();

  React.useEffect(() => {
    bi.report(
      groupsPageView({
        origin: 'list view',
      }),
    );
  }, []);

  const withTitle = settings.get(settingsParams.showHeaderTitle);
  const groupListTitle = settings.get(settingsParams.groupListTitle);
  const withSearchSortingBox = settings.get(settingsParams.showSearchSorting);

  const listTitleFont = styles.get(stylesParams.listTitleFont);
  const headerTag = listTitleFont.htmlTag;

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <div className={classes.header}>
        <Text
          data-hook={DATA_HOOKS.groupListTitle}
          tagName={headerTag || 'h1'}
          typography={TextTypography.largeTitle}
          className={st(classes.title, {
            headerAlignment: settings.get(settingsParams.headerAlignment),
          })}
        >
          {withTitle &&
            (groupListTitle == null
              ? t('groups-web.settings.TextTab.groups.default')
              : groupListTitle)}
        </Text>
        <CreateGroupButton
          priority={ButtonPriority.secondary}
          data-hook={DATA_HOOKS.createNewButton}
          onClick={props.onCreateGroupClick}
          className={classes.createNewButton}
          biOrigin="plus_btn"
        >
          {t('groups-web.btn.create-new')}
        </CreateGroupButton>
      </div>
      <GroupRequestsList />
      {withSearchSortingBox && (
        <SearchSortingBox
          onSearch={handleSearch}
          onSort={actions.sortGroups}
          initialSortSelectedId={groupsSortBy as string}
        />
      )}
      <GroupList
        data-hook={DATA_HOOKS.groupList}
        onCreateGroupClick={props.onCreateGroupClick}
        goToGroup={actions ? actions.goToGroup : undefined}
        mobile={isMobile}
      />
    </div>
  );

  function handleSearch(value: string) {
    bi.report(
      groupsSearchForGroup({
        group_name: value,
        origin: 'top_panel_editbox',
        userEntry: BIUserEntry.SITE,
      }),
    );
    actions.searchGroups(value);
  }
};
