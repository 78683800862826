import React from 'react';

import { st, classes } from './Box.st.css';

export interface BoxProps {
  article?: boolean;
  withTopBottomBorders?: boolean;
  withSideBorders?: boolean;
}

type Props = BoxProps & JSX.IntrinsicElements['article'];

/**
 * Box
 */
export const Box = React.forwardRef<HTMLElement, Props>(
  (
    { article, children, withSideBorders, withTopBottomBorders, ...props },
    ref,
  ) => {
    return (
      <article
        {...props}
        className={st(
          classes.root,
          { article, withSideBorders, withTopBottomBorders } as any,
          props.className,
        )}
        ref={ref}
      >
        {children}
      </article>
    );
  },
);

Box.defaultProps = {
  withSideBorders: true,
  withTopBottomBorders: true,
};
Box.displayName = 'Box';
