import React from 'react';
import { MemberInvites } from './MemberInvites';

export const MemberInvitesContext = React.createContext<MemberInvites>({
  memberInvitesLink: '',
  loadMemberInvitesLink() {},
});

export const MemberInvitesProvider = MemberInvitesContext.Provider;
export const MemberInvitesConsumer = MemberInvitesContext.Consumer;
